import React from 'react';
import styled from 'styled-components';
import Layout from "../../components/layout";
import SectionFooter from '../../components/sections/section-footer/SectionFooter';
import { theme } from "../../utils/theme"
import {Grid} from '@material-ui/core';
import {Link, graphql } from 'gatsby';
import Img from "gatsby-image"


const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
  overflow-y: visible;
  overflow-x: hidden;

  img {
    width: 100%;
    height: auto;
  }

  p, span {
    color: ${props => props.theme.colors.primary};
    font-weight: 300;
  }

  p {
    text-align: left;
    line-height: 1.2;
    margin-top: 20px;
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
`

const Section1 = styled.div`
  height: 100vh;
  padding-top: 50px;
  

  .port-sec1-img {
    /* min-width: 300px;
    width: 100%; */
  }

  p {
    margin-top: 50px;
  }

  @media ${props => props.theme.breaks.down('md')} {
    height: auto;
    /* margin-bottom: 80px; */

    .port-sec1-img {
      width: 100%;
      height: 30vh;
      object-fit: cover;
    }
    p {
      padding: 0 20px;
      margin-top: 50px;
      margin-bottom: 30px;
    }

    }
`

const List = styled.div`
  padding-top: 50px;
  padding-left: 50px;

  .list-header {
    display: block;
    margin-top: 30px;
    margin-bottom: 5px;
    font-weight: 500;

  }

  @media ${props => props.theme.breaks.down('md')} {
    padding: 50px 20px 0 20px;
  }

`

const Section2 = styled.div`

  .image-half {
    max-height: 500px;
    overflow: hidden;

    img {
      height: auto;
      width: 100%; 
      object-fit: cover;
    }
  }

  #halfer {
    max-height: none;
    height: auto;
  }

  

  @media ${props => props.theme.breaks.down('md')} {
    padding-top: 30px;
    margin-bottom: 50px;
    /* height: 100vh; */
    div {
      min-height: 25rem;
      max-height: 50%;
    }
  }
`

const Section3 = styled.div`
  /* height: 100vh; */
  padding-top: 100px;

  p {
    text-align: left;
    margin-top: 50px;
  }

  @media ${props => props.theme.breaks.down('md')} {
    padding-top: 0;

    p {
      padding: 20px;
      text-align: center;
    }
  }
`

const Section4 = styled.div`
  margin-top: 150px;
  /* background-color: pink; */
  
  .underlay-image {
    height: 85vh;

    img {
      object-fit: cover;
    }
  }
  

  .overlay-image {
    width: 55%;
    margin: -150px auto 150px auto;

    img {
      /* max-height: 600px; */
      height: auto;
      object-fit: cover;
    }

    @media ${props => props.theme.breaks.up('xl')} {
      /* margin-top: -18%; */
    } 

    @media ${props => props.theme.breaks.down('md')} {
      margin-top: -50px;
      margin-bottom: 50px;
      width: 85%;
    } 
    
  }

  @media ${props => props.theme.breaks.down('md')} {
      margin-top: 30px;

      .underlay-image {
        height: 45vh;
      }
    } 
`

const Section5 = styled.div`
  img {
    object-fit: cover;
  }
  height: 100vh;
  overflow: hidden;

  @media ${props => props.theme.breaks.down('md')} {
    height: 35vh;
  }
`

const Section6 = styled.div`
  margin-top: 150px;
  img {
    object-fit: cover;
  }
  @media ${props => props.theme.breaks.down('md')} {
    margin-top: 30px;
  }
`

const Section7 = styled(Section4)`
  /* .overlay-image-7 {
    width: 55%;
    margin: -30% auto 150px auto;

    img {
      height: auto;
      object-fit: cover;
    }

    @media ${props => props.theme.breaks.down('lg')} {
      margin-top: -20%;
    } 

    @media ${props => props.theme.breaks.down('md')} {
      margin-top: 30px;
      width: 85%;
      margin-bottom: 20px;
    }  
  /* } */

  @media ${props => props.theme.breaks.down('md')} {
    p {
      padding: 0 50px;
      text-align: center;
    }
  }
`

const Section8 = styled(Section2)`
   margin-top: 100px;

 /* img {
    max-height: 500px;
  }

  @media ${props => props.theme.breaks.down('md')} {
    img {
      max-height: 700px;
    }
  } */
`

const Section9 = styled(Section5)`
  margin-top: 100px;

  @media ${props => props.theme.breaks.down('md')} {
    div {
      min-height: 30rem;
    }
  }
`

const Section10 = styled(Section3)`
  margin-top: 50px;
  h2 {
    color: ${props => props.theme.colors.body};
    font-weight: 700;
    font-size: 7rem;
    width: 400px;
    margin: 40px 0;

    @media ${props => props.theme.breaks.down('md')} {
      font-size: 5rem;
      width: 300px;
    }
  }
`

const FixedFooter = styled.div`
  height: 100px;
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  margin: 60px auto 0 auto;
  padding-left: 40px;
  position: relative;
  background-color: ${props => props.theme.colors.dark};

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
  }
`


const PastWorkPage = ({ data }) => {


  const {name, section1, section2, section3, section4, section5, section6, section7, section8, section9, section10} = data.strapiPortfolios;

  const {edges} = data.allStrapiPortfolios
  const names = edges.sort((a, b) => a.node.num - b.node.num).map(({node}) => node.portfolioPath)
  const length = edges.length
  const num = +data.strapiPortfolios.num
  const link = num >= length ? names[0] : names[num];
  return (
    
      <Layout pageTitle={`Portfolio - ${name}`} color={theme.colors.body}>
      <Container>
        
        {section1 ? 
        <Section1 className="port-sec1">
         <Grid container spacing={0}>
           <Grid item xs={false} md={4}></Grid>
           <Grid item xs={12} md={4}>
             <Img fluid={section1.logo.childImageSharp.fluid} alt="logo" className="port-sec1-img"></Img>
           </Grid>
           <Grid item xs={12} md={4}>
             <List>
              <span className="list-header">Client</span>
              <span className="list-item">{section1.listClient}</span>
              <span className="list-header">Industry</span>
              <span className="list-item">{section1.listIndustry}</span>
              <span className="list-header">Location</span>
              <span className="list-item">{section1.listLocation}</span>
             </List>
           </Grid>
           <Grid item md={3}></Grid>
           <Grid item md={6} xs={12}>
             <p>
             {section1.mainText}
             </p>
           </Grid>
           <Grid item md={3}></Grid>
         </Grid>

         </Section1>
         : null }

        {section2 && section2.active ? 
         <Section2>
          <Grid container spacing={0}>
          {section2.image1 &&
            <Grid item xs={12} md={section2.image2 ? 6 : 12}>
              <div className="image-half" id={section2.image2 ? '' : 'halfer'}>
               <Img fluid={section2.image1.childImageSharp.fluid} alt="section2" />
              </div>
              </Grid>
          }
          {section2.image2 &&
            <Grid item xs={12} md={section2.image1 ? 6 : 12}>
              <div className="image-half" id={section2.image1 ? '' : 'halfer'}>
               <Img fluid={section2.image2.childImageSharp.fluid} alt="section2" />
              </div>
              </Grid>
          }
          </Grid>
         </Section2>

          : null}


        {section3 && section3.active ? 
         <Section3>
           <Grid container spacing={0}>
             <Grid item md={2} xs={1}></Grid>

             <Grid item md={8} xs={10}>
               <div>
               {section3.image ? <Img fluid={section3.image.childImageSharp.fluid} /> : null}
               </div>
            </Grid>

             <Grid item md={2} xs={1}></Grid>

             <Grid item md={3}></Grid>
           <Grid item md={6} xs={12}>
             <p>
              {section3.mainText}
             </p>
           </Grid>
           <Grid item md={3}></Grid>
            
           </Grid>
         </Section3>
         : null }


        {section4 && section4.active ? 
         <Section4>
           <div className="underlay-image">
           {section4.imageLarge ? 
           <Img fluid={section4.imageLarge.childImageSharp.fluid} alt="section4-img" />
            : null }
            </div>

           <div className="overlay-image">
           {section4.imageSmall ? 
           <Img fluid={section4.imageSmall.childImageSharp.fluid} alt="section4-img" />
            : null}
           </div>
         </Section4>
         : null }


          {section5 && section5.active ? 
         <Section5>
           <div>
             {section5.image &&
            <Img fluid={section5.image.childImageSharp.fluid} alt="section-5"></Img>
            }
           </div>
         </Section5>
          : null }


          {section6 && section6.active ? 
         <Section6>
           <Grid container spacing={6} alignItems="center">
             <Grid item md={1}></Grid>

             
            <Grid item md={4} xs={10}>
              {section6.imageSmall ? <Img fluid={section6.imageSmall.childImageSharp.fluid}></Img>
              : null}
            </Grid>

             <Grid item md={6} xs={12}>
             {section6.imageLarge ? 
               <Img fluid={section6.imageLarge.childImageSharp.fluid}></Img>
               : null }
             </Grid>

             <Grid item md={1}></Grid>
           </Grid>
         </Section6>
         : null }


          {section7 && section7.active ? 
         <Section7>
           <div className="underlay-image">
           {section7.imageLarge ? 
         <Img fluid={section7.imageLarge.childImageSharp.fluid} alt="section4-img" />
         : null }
         </div>

          <div className="overlay-image">
          {section7.imageSmall ? 
          <Img fluid={section7.imageSmall.childImageSharp.fluid} alt="section4-img" />
          : null }
          </div>

          <Grid container>
            <Grid item xs md={3}></Grid>
            <Grid item xs={12} md={6}>
              <p>
              {section7.mainText}
              </p>
            </Grid>
            <Grid item xs md={3}></Grid>
           </Grid>
         </Section7>
         : null }

      {section8 && section8.active ? 
         <Section8>
          <Grid container spacing={0}>
          {section8.image1 &&
            <Grid item xs={12} md={section8.image2 ? 6 : 12}>
              <div className="image-half">
               <Img fluid={section8.image1.childImageSharp.fluid} alt="section8" />
              </div>
              </Grid>
          }
          {section8.image2 &&
            <Grid item xs={12} md={section8.image1 ? 6 : 12}>
              <div className="image-half">
               <Img fluid={section8.image2.childImageSharp.fluid} alt="section8" />
              </div>
              </Grid>
          }
          </Grid>
         </Section8>

          : null}

        {section9 && section9.active ? 
         <Section9>
           <div>
            <Img fluid={section9.image.childImageSharp.fluid} alt="section-9"></Img>
           </div>
         </Section9>
          : null }

          {section10 ? 
         <Section10>
           <Grid container spacing={0}>
             <Grid item md={2} xs={1}></Grid>

             <Grid item md={8} xs={10}>
               <div>
             {section10.image ? 
               <Img fluid={section10.image.childImageSharp.fluid} alt="section10-img" />
               : null }
               </div>
            </Grid>

             <Grid item md={2} xs={1}></Grid>

             <Grid item md={3} xs={1}></Grid>
           <Grid item md={6} xs={8}>
             <Link to={link}>
              <h2>
                next project
              </h2>
             </Link>
           </Grid>
           <Grid item md={3} xs={3}></Grid>
            
           </Grid>
         </Section10>
          : null}

         <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>
      </Container>
      </Layout>
    
)
}

export default PastWorkPage;

export const query = graphql`
query ($name: String) {
  allStrapiPortfolios {
    edges {
      node {
        name
        num
        portfolioPath: gatsbyPath(filePath: "/portfolios/{StrapiPortfolios.name}")
      }
    }
  }
  strapiPortfolios(name: {eq: $name}) {
    name
    num
    section1 {
      logo {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      mainText
      listClient
      listIndustry
      listLocation
      active
    }
    section2 {
      image1 {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
        }
      }
      image2 {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
        }
      }
      active
    }
    section3 {
      active
      mainText
      image {
        childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
    section4 {
      active
      imageLarge {
        childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
      }
      imageSmall {
        childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
    section5 {
      active
      image {
        childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
    section6 {
      active
      imageLarge {
        childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
      }
      imageSmall {
        childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
    section7 {
      active
      mainText
      imageLarge {
        childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
      }
      imageSmall {
        childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
    section8 {
      image1 {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
        }
      }
      image2 {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
        }
      }
      active
    }
    section9 {
      active
      image {
        childImageSharp {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
    section10 {
      active
      image {
        childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
  }
}
`
